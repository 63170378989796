import { http } from '@/lib/http'
import {
  ReconciliationExplainType,
  ReconciliationTaskListType,
  ReconciliationTaskDetailType,
  ReconciliationTaskReasonType,
  ReconciliationReportListType,
  ReconciliationTaskSummaryType,
  ReconciliationMatchedListType,
  ReconciliationTaskRuleListType,
  ReconciliationMatchedDetailType,
  DetailsReconciliationLedgerType,
  ReconciliationTaskDataSetListType,
  ReconciliationTaskMatchedByItemType,
  ReconciliationTaskBalanceListItemType,
  DetailsReconciliationTransactionType
} from '#/ReconciliationTypes'

export default {
  /**
   * 获取对账工作任务列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   */
  getReconciliationTaskList: (entityId: string, params: object) => {
    return http.request<ResponseData<ReconciliationTaskListType>>('get', `/entity/${entityId}/reconciliation/task`, {
      params
    })
  },
  /**
   * 创建对账工作任务
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.name
   * @property {string} data.dataSetTypeA 对账数据类型
   * @property {string} data.dataSetTypeB 对账数据类型
   * @property {string} data.dataSetAName 对账数据名称
   * @property {string} data.dataSetBName 对账数据名称
   * @property {string} data.fromDatetimeStr 对账开始时间 "2026-06-01 00:00:00"
   * @property {string} data.toDatetimeStr 对账结束时间 "2026-06-30 23:59:59"
   */
  createReconciliationTask: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task`, {
      data
    })
  },
  /**
   * 修改对账工作任务
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {Object} data
   * @property {string} [data.name] 对账任务名称
   * @property {string} [data.dataSetAName] 对账数据名称
   * @property {string} [data.dataSetBName] 对账数据名称
   * @property {string} [data.fromDatetimeStr] 对账开始时间 "2026-06-01 00:00:00"
   * @property {string} [data.toDatetimeStr] 对账结束时间 "2026-06-30 23:59:59"
   */
  updateReconciliationTask: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}`, {
      data
    })
  },
  /**
   * 获取对账工作任务详情
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   */
  getReconciliationTaskDetail: (entityId: string, reconciliationTaskId: string) => {
    return http.request<ResponseData<ReconciliationTaskDetailType>>(
      'get',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}`
    )
  },
  /**
   * 删除对账工作任务
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {Object} params 附加参数
   * @property {boolean} [params.isRemoveReconciliationAdjustment] 是否删除调账
   */
  deleteReconciliationTask: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('delete', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}`, { data })
  },
  /**
   * 复制对账工作任务
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {Object} data
   * @property {string} [data.name] 对账任务名称
   * @property {string} [data.fromDatetimeStr] 对账开始时间 "2026-06-01 00:00:00"
   * @property {string} [data.toDatetimeStr] 对账结束时间 "2026-06-30 23:59:59"
   */
  copyReconciliationTask: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/copy`, { data })
  },
  /**
   * 装载对账工作数据
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {Object} data 状态数据参数
   * @property {boolean} [data.isRemoveReconciliationAdjustment] 是否删除对账调账
   */
  loadReconciliationTaskData: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/load`, { data })
  },
  /**
   * 对账工作数据集列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   */
  getReconciliationTaskDataSetList: (entityId: string, reconciliationTaskId: string) => {
    return http.request<ResponseData<ReconciliationTaskDataSetListType>>(
      'get',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/dataSet`
    )
  },
  /**
   * 添加对账工作数据集
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {Object} data
   * @property {string} data.name 数据集名称
   * @property {string} data.side 数据集所属方 A/B
   * @property {string} data.dataType 数据类型
   * @property {Array} data.conditions 条件
   */
  addReconciliationTaskDataSet: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/dataSet`, {
      data
    })
  },
  /**
   * 修改对账工作数据集
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {string} reconciliationDataSetId 数据集id
   * @param {Object} data
   * @property {string} data.name 数据集名称
   * @property {Array} data.conditions 条件
   */
  updateReconciliationTaskDataSet: (
    entityId: string,
    reconciliationTaskId: string,
    reconciliationDataSetId: string,
    data: object
  ) => {
    return http.request(
      'put',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/dataSet/${reconciliationDataSetId}`,
      {
        data
      }
    )
  },
  /**
   * 删除对账工作数据集
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {string} reconciliationDataSetId 数据集id
   */
  deleteReconciliationTaskDataSet: (
    entityId: string,
    reconciliationTaskId: string,
    reconciliationDataSetId: string
  ) => {
    return http.request(
      'delete',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/dataSet/${reconciliationDataSetId}`
    )
  },
  /**
   * 对账工作发生额列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   */
  getReconciliationTaskBalanceList: (entityId: string, reconciliationTaskId: string) => {
    return http.request<ResponseData<ReconciliationTaskBalanceListItemType>>(
      'get',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/balance`
    )
  },
  /**
   * 获取对账明细transaction列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} params
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   * @property {number} params.type PENDING｜PROCESSED
   * @param {Object} data
   * @property {string} [data.status] 状态
   * @property {string} [data.reconciliationReasonId] 对账原因id
   * @property {Array} [data.transactionType] 交易类型
   * @property {Object} [data.datime] 日期
   * @property {Array} [data.currency] 币种
   * @property {Object} [data.amount] 原始金额
   * @property {Object} [data.amountFC] 报告金额
   * @property {string[]} [data.entityAccountId] 账户
   * @property {string} [data.accountType] 账户类型
   * @property {string} [data.txHash] 交易hash
   */
  getReconciliationTransactionList: (
    entityId: string,
    reconciliationTaskId: string,
    params: object,
    data: object = {}
  ) => {
    return http.request<ResponseData<DetailsReconciliationTransactionType>>(
      'post',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/transaction`,
      {
        params,
        data
      }
    )
  },
  /**
   * 获取对账明细ledger Pending列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} params
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   * @property {number} params.type PENDING｜PROCESSED
   * @param {Object} data
   * @property {string} [data.status] 状态
   * @property {string} [data.reconciliationReasonId] 对账原因id
   * @property {Array} [data.journalTypeIds] 交易类型
   * @property {Object} [data.datime] 日期
   * @property {Array} [data.currency] 币种
   * @property {Object} [data.amount] 原始金额
   * @property {Object} [data.amountFC] 报告金额
   * @property {string[]} [data.entityAccountId] 账户
   * @property {string} [data.accountType] 账户类型
   * @property {String} [data.referenceNo] 交易参考号
   * @property {String} [data.journalNo] 分类账号
   */
  getReconciliationLedgerList: (entityId: string, reconciliationTaskId: string, params: object, data: object = {}) => {
    return http.request<ResponseData<DetailsReconciliationLedgerType>>(
      'post',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/ledger`,
      {
        params,
        data
      }
    )
  },
  /**
   * 对账明细统计
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   */
  getReconciliationTaskSummary: (entityId: string, reconciliationTaskId: string) => {
    return http.request<ResponseData<ReconciliationTaskSummaryType>>(
      'get',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/dataStat`
    )
  },
  /**
   * 明细添加解释
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} data
   * @property {string} [data.reconciliationReasonId] 对账原因id
   * @property {string} [data.name] 对账原因名称
   * @property {string} [data.description] 对账原因描述
   * @property {string} [data.side] 对账方向
   * @property {string[]} [data.reconciliationDataIds] 数据ids
   * @property {string} [data.chartOfAccountId] 科目id
   * @property {Array} [data.auxiliaryValueList] 辅助核算
   * @property {string} [data.memo] 备注
   */
  addReconciliationTaskReason: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/bulkExplain`, { data })
  },
  /**
   * 明细清除解释
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} data
   * @property {string[]} [data.reconciliationDataIds] 数据ids
   * @property {boolean} [data.isRemoveReconciliationAdjustment] 是否移除对账journal调整
   */
  clearReconciliationTaskReason: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/bulkUnExplain`, {
      data
    })
  },
  /**
   * 明细修改解释
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} data
   * @property {string} [data.reconciliationReasonId] 对账原因id
   * @property {string} [data.name] 对账原因名称
   * @property {string} [data.description] 对账原因描述
   * @property {string} [data.side] 对账方向
   * @property {string} [data.journalAction] 'KEEP/CREATE/DELETE'
   * @property {string} [data.reconciliationDataId] 数据id
   * @property {string} [data.chartOfAccountId] 科目id
   * @property {Array} [data.auxiliaryValueList] 辅助核算
   * @property {string} [data.memo] 备注
   */
  updateReconciliationTaskReason: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/updateExplain`, {
      data
    })
  },
  /**
   * 对账明细解释详情
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {Object} data
   * @property {string} [data.side] 对账方向
   * @property {string} [data.reconciliationDataId] 数据id
   */
  getReconciliationTaskReasonDetail: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request<ResponseData<ReconciliationExplainType>>(
      'post',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/getExplain`,
      {
        data
      }
    )
  },
  /**
   * 获取对账原因列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   */
  getReconciliationTaskReasonList: (entityId: string, reconciliationTaskId: string) => {
    return http.request<ResponseData<ReconciliationTaskReasonType[]>>(
      'get',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/reason`
    )
  },
  /**
   * 手动匹配对账
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {Object} data
   * @property {string[]} data.sideAReconciliationDataIds
   * @property {string[]} data.sideBReconciliationDataIds
   * @property {string} [data.description] 对账原因描述
   * @property {string} [data.reconciliationReasonId] 对账原因id
   * @property {string} [data.name] 对账原因名称
   * @property {string} [data.chartOfAccountId] 科目id
   * @property {Array} [data.auxiliaryValueList] 辅助核算
   * @property {string} [data.memo] 备注
   */
  manualReconciliationMatch: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/match`, {
      data
    })
  },
  /**
   * 解除匹配对账
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作任务id
   * @param {Object} data
   * @property {string[]} [data.reconciliationMatchIds] 对账匹配id列表
   * @property {boolean} [data.isRemoveReconciliationJournal] 是否移除对账journal
   */
  manualReconciliationUnmatched: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/removeMatch`, {
      data
    })
  },
  /**
   * 获取对账工作规则列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} params
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   */
  getReconciliationTaskRuleList: (entityId: string, reconciliationTaskId: string, params: object) => {
    return http.request<ResponseData<ReconciliationTaskRuleListType>>(
      'get',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/rule`,
      { params }
    )
  },
  /**
   * 创建对账规则
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} data
   * @property {string} data.name 对账规则名称
   * @property {Array} data.toleranceList 宽容度列表
   * @property {string} [data.description] 对账原因描述
   * @property {string} [data.reconciliationReasonId] 对账原因id
   * @property {string} [data.reasonName] 对账原因名称
   * @property {string} [data.chartOfAccountId] 科目id
   * @property {Array} [data.auxiliaryValueList] 辅助核算
   * @property {string} [data.memo] 备注
   */
  createReconciliationTaskRule: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/rule`, { data })
  },
  /**
   * 修改对账规则
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {string} ruleId 对账规则id
   * @param {Object} data
   * @property {string} data.name 对账规则名称
   * @property {Array} data.toleranceList 宽容度列表
   * @property {string} [data.description] 对账原因描述
   * @property {string} [data.reconciliationReasonId] 对账原因id
   * @property {string} [data.reasonName] 对账原因名称
   * @property {string} [data.chartOfAccountId] 科目id
   * @property {Array} [data.auxiliaryValueList] 辅助核算
   * @property {string} [data.memo] 备注
   */
  updateReconciliationTaskRule: (entityId: string, reconciliationTaskId: string, ruleId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/rule/${ruleId}`, {
      data
    })
  },
  /**
   * 删除对账规则
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {string} reconciliationRuleId 对账规则id
   */
  deleteReconciliationTaskRule: (entityId: string, reconciliationTaskId: string, reconciliationRuleId: string) => {
    return http.request(
      'delete',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/rule/${reconciliationRuleId}`
    )
  },
  /**
   * 执行规则对账
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   */
  executeReconciliationTaskRule: (entityId: string, reconciliationTaskId: string) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/executeRules`)
  },
  /**
   * 修改对账规则优先级
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} data object
   * @property {string} data.reconciliationRuleIds 对账规则id集合
   */
  editAutomationRulePriority: (entityId: string, reconciliationTaskId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/rule/priority`, {
      data
    })
  },
  /**
   * 获取已对账列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} params
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   * @param {Object} data
   * @property {string} [data.matchNo] 匹配号
   * @property {string} [data.ledgerJournalNo] 分类账号
   * @property {string} [data.ledgerReferenceNo] 分类账参考号
   * @property {string} [data.transactionTxHash] 交易hash
   * @property {string} [data.reconciliationReasonId] 对账原因id
   * @property {string} [data.reconciliationRuleId] 对账规则id
   * @property {string} [data.userId] 用户id
   */
  getReconciliationMatchList: (entityId: string, reconciliationTaskId: string, params: any, data: object) => {
    return http.request<ResponseData<ReconciliationMatchedListType>>(
      'post',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/searchMatch?page=${params.page}&limit=${params.limit}`,
      { data }
    )
  },
  /**
   * 获取已对账详情
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {string} reconciliationMatchId 对账匹配id
   */
  getReconciliationMatchDetail: (entityId: string, reconciliationTaskId: string, reconciliationMatchId: string) => {
    return http.request<ResponseData<ReconciliationMatchedDetailType>>(
      'get',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/match/${reconciliationMatchId}`
    )
  },
  /**
   * 修改对账匹配
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {string} reconciliationMatchId 对账匹配id
   * @param {Object} data
   * @property {string} [data.reconciliationReasonId] 对账原因id
   * @property {string} [data.name] 对账原因名称
   * @property {string} [data.description] 对账原因描述
   * @property {string} [data.journalAction] 'KEEP/CREATE/DELETE'
   * @property {string} [data.chartOfAccountId] 科目id
   * @property {Array} [data.auxiliaryValueList] 辅助核算
   * @property {string} [data.memo] 备注
   */
  updateReconciliationMatch: (
    entityId: string,
    reconciliationTaskId: string,
    reconciliationMatchId: string,
    data: object
  ) => {
    return http.request(
      'post',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/match/${reconciliationMatchId}`,
      {
        data
      }
    )
  },
  /**
   * 获取已对账的matchBy列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   */
  getReconciliationMatchByList: (entityId: string, reconciliationTaskId: string) => {
    return http.request<ResponseData<ReconciliationTaskMatchedByItemType>>(
      'get',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/matchBy`
    )
  },
  /**
   * 获取对账报告列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   */
  getReconciliationReportList: (entityId: string, reconciliationTaskId: string) => {
    return http.request<ResponseData<ReconciliationReportListType>>(
      'get',
      `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/report`
    )
  },
  /**
   * 获取对账操作日志列表
   * @param {string} entityId 主体id
   * @param {string} reconciliationTaskId 对账工作id
   * @param {Object} params
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   */
  getReconciliationSetLogList: (entityId: string, reconciliationTaskId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/history`, { params })
  }
}
